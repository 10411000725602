import { useEffect } from "react";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Accordion, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "./index.scss";
import CustomInput from "components/Common/CustomInput";

const EditInformationStatementForm = ({
  statementPage,
  setEditInformationForm,
  loading,
  handleInputChangeInformation,
  editInformationForm,
}) => {
  const { Switch } = Form;
  useEffect(() => {
    if (statementPage?.information) {
      const summaryStatement = statementPage?.information?.summary;
      setEditInformationForm({
        opening_balance: summaryStatement?.openingBalance || "0.00",
        total_debit_amount: summaryStatement?.totalDebitAmount || "0.00",
        total_credit_amount: summaryStatement?.totalCreditAmount || "0.00",
        closing_balance: summaryStatement?.closingBalance || "0.00",
        fraud_analysis_ready: statementPage?.information?.fraudAnalysisReady
          ? statementPage?.information?.fraudAnalysisReady
          : false,
      });
    }
  }, [statementPage]);

  return (
    <div className="edit-information-form">
      <Accordion className="mb-4" alwaysOpen={false}>
        <Accordion.Item eventKey="editInformationForm">
          <Accordion.Header>Edit Information</Accordion.Header>
          <Accordion.Body>
            <Row className="mb-4">
              <Col xs={2} className="d-flex align-items-center">
                <p className="me-2">Opening Balance</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="opening-balance-info">openingBalance / opening_balance</Tooltip>}
                >
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </OverlayTrigger>
              </Col>
              <Col xs={6} className="text-left">
                {loading ? (
                  <Skeleton height={20} />
                ) : (
                  <CustomInput
                    name="opening_balance"
                    type="text"
                    required
                    onChange={(e) => handleInputChangeInformation(e, "opening_balance")}
                    value={editInformationForm.opening_balance}
                    disabled={loading}
                    onlyAcceptNumbers
                    canAcceptDecimals
                    inputClassName="text-end"
                    placeholder={"0.00"}
                    iconPosition=""
                    maxDecimals={2}
                  />
                )}
              </Col>
              {!Number.isNaN(Number(editInformationForm.opening_balance)) && (
                <Col xs={4} className="d-flex align-items-center">
                  {Number(editInformationForm.opening_balance).toLocaleString("id-ID", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 17,
                  })}
                </Col>
              )}
            </Row>
            <Row className="mb-4">
              <Col xs={2} className="d-flex align-items-center">
                <p className="me-2">Total Debit Amount</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="opening-balance-info">totalDebitAmount / total_debit_amount</Tooltip>}
                >
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </OverlayTrigger>
              </Col>
              <Col xs={6} className="text-left">
                {loading ? (
                  <Skeleton height={20} />
                ) : (
                  <CustomInput
                    name="total_debit_amount"
                    type="text"
                    required
                    onChange={(e) => handleInputChangeInformation(e, "total_debit_amount")}
                    value={editInformationForm.total_debit_amount}
                    disabled={loading}
                    onlyAcceptNumbers
                    canAcceptDecimals
                    inputClassName="text-end"
                    placeholder={"0.00"}
                    iconPosition=""
                    maxDecimals={2}
                  />
                )}
              </Col>
              {!Number.isNaN(Number(editInformationForm.total_debit_amount)) && (
                <Col xs={4} className="d-flex align-items-center">
                  {Number(editInformationForm.total_debit_amount).toLocaleString("id-ID", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Col>
              )}
            </Row>
            <Row className="mb-4">
              <Col xs={2} className="d-flex align-items-center">
                <p className="me-2">Total Credit Amount</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="opening-balance-info">totalCreditAmount / total_credit_amount</Tooltip>}
                >
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </OverlayTrigger>
              </Col>
              <Col xs={6} className="text-left">
                {loading ? (
                  <Skeleton height={20} />
                ) : (
                  <CustomInput
                    name="total_credit_amount"
                    type="text"
                    required
                    onChange={(e) => handleInputChangeInformation(e, "total_credit_amount")}
                    value={editInformationForm.total_credit_amount}
                    disabled={loading}
                    onlyAcceptNumbers
                    canAcceptDecimals
                    inputClassName="text-end"
                    placeholder={"0.00"}
                    iconPosition=""
                    maxDecimals={2}
                  />
                )}
              </Col>
              {!Number.isNaN(Number(editInformationForm.total_credit_amount)) && (
                <Col xs={4} className="d-flex align-items-center">
                  {Number(editInformationForm.total_credit_amount).toLocaleString("id-ID", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Col>
              )}
            </Row>
            <Row className="mb-4">
              <Col xs={2} className="d-flex align-items-center">
                <p className="me-2">Closing Balance</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="opening-balance-info">closingBalance / closing_balance</Tooltip>}
                >
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </OverlayTrigger>
              </Col>
              <Col xs={6} className="text-left">
                {loading ? (
                  <Skeleton height={20} />
                ) : (
                  <CustomInput
                    name="closing_balance"
                    type="text"
                    required
                    onChange={(e) => handleInputChangeInformation(e, "closing_balance")}
                    value={editInformationForm.closing_balance}
                    disabled={loading}
                    onlyAcceptNumbers
                    canAcceptDecimals
                    inputClassName="text-end"
                    placeholder={"0.00"}
                    iconPosition=""
                    maxDecimals={2}
                  />
                )}
              </Col>
              {!Number.isNaN(Number(editInformationForm.closing_balance)) && (
                <Col xs={4} className="d-flex align-items-center">
                  {Number(editInformationForm.closing_balance).toLocaleString("id-ID", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Col>
              )}
            </Row>
            <Row className="mb-4">
              <Col xs={2} className="d-flex align-items-center">
                <p className="me-2">Fraud Analysis Ready Status</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="opening-balance-info">fraudAnalysisReady / fraud_analysis_ready</Tooltip>}
                >
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </OverlayTrigger>
              </Col>
              <Col xs={6} className="d-flex text-left align-items-center">
                {loading ? (
                  <Skeleton height={20} />
                ) : (
                  <div className="d-flex">
                    <Switch
                      checked={editInformationForm.fraud_analysis_ready}
                      onChange={(e) => handleInputChangeInformation(e, "fraud_analysis_ready", "checked")}
                    />
                    <span className="ms-2">Status: {String(editInformationForm.fraud_analysis_ready)}</span>
                  </div>
                )}
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default EditInformationStatementForm;
