import React, { useState, useImperativeHandle, forwardRef, useRef } from "react";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import TextFindReplaceComponent from "./TextFindReplace";
import "./index.scss";
import ReplacePosition from "./ReplacePosition";

/**
 * FindReplaceComponent - A React component for finding and replacing text within string fields of objects in a data array.
 *
 * @param {Object} props - The props object.
 * @param {Array<Object>} props.data - The array of objects containing data. The component only processes string data types in these objects.
 * @param {Function} props.setData - Function to update the data array after replacements.
 * @param {Array<string>} [props.includePropertyToFind=[]] - An optional array of property names that specify which fields should be included in the find and replace operations. Only string fields will be processed.
 * @param {boolean} [props.enabledSticky=true] - An optional flag to enable or disable sticky positioning for the component.
 * @param {React.Ref} ref - A React ref that can be used by the parent component to interact with the component's methods.
 * @param {boolean} [props.loading=false] - An boolean to disabling/enabling find replace component
 *
 * @example
 * // Example of using FindReplaceComponent
 * const data = [
 *   { timestamp: "2024-01-01 18:15:39", description: "IBIZ ERDEHA MULTI N TO SUCI JAYA ABADHI", amount: "62500000.00" },
 *   { timestamp: "2024-01-02 19:25:39", description: "TRANSFER TO JOHN DOE", amount: "5000000.00" }
 * ];
 *
 * const setData = (updatedData) => { ... };
 *
 * <FindReplaceComponent
 *   data={data}
 *   setData={setData}
 *   includePropertyToFind={['description']}
 *   enabledSticky={true}
 *   loading={false}
 * />
 */

const FindReplaceComponent = forwardRef(
  ({ data = [], setData, includePropertyToFind = [], enabledSticky = true, loading = false }, ref) => {
    const [activeKey, setActiveKey] = useState("find-replace");

    const textFindReplace = useRef();

    // Expose methods to parent component
    useImperativeHandle(ref, () => ({
      handleFind: textFindReplace.current.handleFind,
      handleReplace: textFindReplace.current.handleReplace,
      handleReplaceAll: textFindReplace.current.handleReplaceAll,
      resetComponentState: textFindReplace.current.resetComponentState,
    }));

    return (
      <div className={`find-replace-container${enabledSticky ? " sticky-component" : ""}`}>
        <Accordion className="mb-4" defaultActiveKey={["findAndReplace"]} alwaysOpen>
          <Accordion.Item eventKey="findAndReplace">
            <Accordion.Header>Find & Replace</Accordion.Header>
            <Accordion.Body>
              <Tabs activeKey={activeKey} onSelect={(k) => setActiveKey(k)} className="my-3" fill mountOnEnter>
                <Tab eventKey="find-replace" title="Find & Replace">
                  <TextFindReplaceComponent
                    key={activeKey}
                    includePropertyToFind={includePropertyToFind}
                    setData={setData}
                    data={data}
                    ref={textFindReplace}
                    loading={loading}
                  />
                </Tab>
                <Tab eventKey="replace-cr-db" title="Replace CR / DB">
                  <ReplacePosition key={activeKey} setData={setData} data={data} loading={loading} />
                </Tab>
              </Tabs>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  },
);

// Set the display name for better debugging
FindReplaceComponent.displayName = "FindReplaceComponent";

export default FindReplaceComponent;
