import { Container } from "react-bootstrap";
import NavigationBar from "components/NavigationBar";
import MergePefindoDashboardData from "components/Pefindo/MergePefindoDashboardData";
import MergePefindoDashboardMeta from "components/Pefindo/MergePefindoDashboardMeta";
import Footer from "components/Footer";

const Dashboard = () => {
  return (
    <>
      <NavigationBar />
      <Container style={{ marginBottom: "50px" }}>
        <h2 className="mb-4">Pefindo</h2>
        <div className="mb-4">
          <MergePefindoDashboardMeta />
        </div>
        <MergePefindoDashboardData />
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
