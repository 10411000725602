/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { FormControl, ListGroup, InputGroup } from "react-bootstrap";
import "./index.scss"; // Import custom CSS

function debounce(func, delay) {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
}

/**
 * AutocompleteInput component for handling input with debounced API search
 * @param {string} defaultValue - Default value to pre-fill in the input
 * @param {Array} listData - Data to be used for the list (results)
 * @param {Function} getData - Function to fetch data for autocomplete (API call)
 * @param {string} type - Type of data to fetch
 * @param {boolean} enabledBoldOnResult - Whether to bold the matching results
 * @param {string} propertyName - Property name of the object to use for for selected
 * @param {string} inputPlaceholder - Placeholder text for the input field
 * @param {Function} onChangeValue - Function to change defaultValue
 * @param {string} displayPropertyName - Property name of the object to use for display
 * @param {boolean} getAllPropertyInSelectedData - Whether to get all property or not in selectedData
 * @param {boolean} enableBlurReset - Whether to clear query on blur if no valid selection
 * @returns {JSX.Element} - The AutocompleteInput component
 */
const AutocompleteInput = ({
  defaultValue = "",
  listData = [],
  getData,
  type = "",
  enabledBoldOnResult = true,
  propertyName = "",
  inputPlaceholder,
  onChangeValue,
  displayPropertyName = "",
  getAllPropertyInSelectedData = false,
  enableBlurReset = true,
}) => {
  const [query, setQuery] = useState(defaultValue || "");
  const [filteredItems, setFilteredItems] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [showList, setShowList] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const wrapperRef = useRef(null);
  const itemRefs = useRef([]);
  const blurTimeoutRef = useRef(null);

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      if (searchQuery.length === 0) {
        setIsSearching(false);
        setFilteredItems([]);
        setHasSearched(false);
        return;
      }

      setHasSearched(true);
      getData(searchQuery, type);
      setIsSearching(false);
      setShowList(true);
    }, 300),
    [getData, type],
  );

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setIsSearching(true);
    debouncedSearch(value);
  };

  const handleChoiceClick = (selectedData) => {
    setQuery(selectedData[propertyName]);
    if (getAllPropertyInSelectedData) {
      onChangeValue(selectedData);
    } else {
      onChangeValue(selectedData[propertyName]);
    }
    setFilteredItems([]);
    setHasSearched(false);
    setShowList(false);
    clearTimeout(blurTimeoutRef.current); // Clear the blur timeout if an item is clicked
  };

  const boldMatch = (text, match) => {
    const escapeRegex = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const searchTerms = match.toLowerCase().trim().split(/\s+/).map(escapeRegex); // Escape each search term
    const regex = new RegExp(`(${searchTerms.join("|")})`, "gi");
    const parts = text.split(regex);

    return (
      <>
        {parts.map((part, index) =>
          searchTerms.includes(part.toLowerCase()) ? <strong key={index}>{part}</strong> : part,
        )}
      </>
    );
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowList(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) => (prevIndex < filteredItems.length - 1 ? prevIndex + 1 : 0));
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredItems.length - 1));
    } else if (e.key === "Enter") {
      if (highlightedIndex >= 0 && highlightedIndex < filteredItems.length) {
        handleChoiceClick(filteredItems[highlightedIndex]);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setQuery(defaultValue);
      setFilteredItems([]);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (listData) {
      setFilteredItems(listData);
    }
  }, [listData]);

  useEffect(() => {
    if (highlightedIndex >= 0 && itemRefs.current[highlightedIndex]) {
      itemRefs.current[highlightedIndex].scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [highlightedIndex]);

  return (
    <div className="autocomplete-wrapper" ref={wrapperRef}>
      <InputGroup>
        <FormControl
          type="text"
          value={query}
          placeholder={inputPlaceholder}
          onChange={handleChange}
          className="autocomplete-input"
          onKeyDown={handleKeyDown}
          onFocus={() => setShowList(true)}
        />
      </InputGroup>
      {query && showList && (
        <ListGroup className={`autocomplete-list ${hasSearched ? "show-border" : ""}`}>
          {isSearching ? (
            <ListGroup.Item className="autocomplete-item">Searching...</ListGroup.Item>
          ) : filteredItems.length > 0 && hasSearched ? (
            filteredItems.map((item, index) => (
              <ListGroup.Item
                key={index}
                className={`autocomplete-item ${highlightedIndex === index ? "highlighted" : ""}`}
                ref={(el) => (itemRefs.current[index] = el)}
                onClick={() => handleChoiceClick(item)}
                aria-selected={highlightedIndex === index}
              >
                {enabledBoldOnResult ? boldMatch(item[displayPropertyName], query) : item[displayPropertyName]}
              </ListGroup.Item>
            ))
          ) : (
            hasSearched && <ListGroup.Item className="autocomplete-item">Result is not found</ListGroup.Item>
          )}
        </ListGroup>
      )}
    </div>
  );
};

export default AutocompleteInput;
