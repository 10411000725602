import { useEffect, useCallback, useState } from "react";
import { Bar } from "react-chartjs-2";
import { usePartnerLog } from "contexts/partner_log";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const options = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
};

const height = 300;

const DailyChart = () => {
  const { getPartnerLogChart, partnerLogDailyChart, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("daily");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={partnerLogDailyChart} options={options} />
        </div>
      )}
    </>
  );
};

const MonthlyChart = () => {
  const { getPartnerLogChart, partnerLogMonthlyChart, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={partnerLogMonthlyChart} options={options} />
        </div>
      )}
    </>
  );
};

const YearlyChart = () => {
  const { getPartnerLogChart, partnerLogYearlyChart, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={partnerLogYearlyChart} options={options} />
        </div>
      )}
    </>
  );
};

const MonthlyBusyHourChart = () => {
  const { getPartnerLogChart, partnerLogMonthlyBusyHourCharts, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly_busy_hour");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {partnerLogMonthlyBusyHourCharts.map((chart, index) => (
            <Col key={index}>
              <Bar data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const YearlyBusyHourChart = () => {
  const { getPartnerLogChart, partnerLogYearlyBusyHourCharts, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly_busy_hour");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {partnerLogYearlyBusyHourCharts.map((chart, index) => (
            <Col key={index}>
              <Bar data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const MonthlyBusyDayChart = () => {
  const { getPartnerLogChart, partnerLogMonthlyBusyDayCharts, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly_busy_day");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {partnerLogMonthlyBusyDayCharts.map((chart, index) => (
            <Col key={index}>
              <Bar data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const YearlyBusyDayChart = () => {
  const { getPartnerLogChart, partnerLogYearlyBusyDayCharts, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly_busy_day");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {partnerLogYearlyBusyDayCharts.map((chart, index) => (
            <Col key={index}>
              <Bar data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const MonthlyBusyWeekdayChart = () => {
  const { getPartnerLogChart, partnerLogMonthlyBusyWeekdayCharts, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly_busy_weekday");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {partnerLogMonthlyBusyWeekdayCharts.map((chart, index) => (
            <Col key={index}>
              <Bar data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const YearlyBusyWeekdayChart = () => {
  const { getPartnerLogChart, partnerLogYearlyBusyWeekdayCharts, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly_busy_weekday");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {partnerLogYearlyBusyWeekdayCharts.map((chart, index) => (
            <Col key={index}>
              <Bar data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const DashboardChart = () => {
  const [key, setKey] = useState("tab-na");
  return (
    <>
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="my-3" fill mountOnEnter>
        <Tab eventKey="tab-na" title="N/A"></Tab>
        <Tab eventKey="tab-daily" title="Daily">
          <DailyChart />
        </Tab>
        <Tab eventKey="tab-monthly" title="Monthly">
          <MonthlyChart />
        </Tab>
        <Tab eventKey="tab-yearly" title="Yearly">
          <YearlyChart />
        </Tab>
        <Tab eventKey="tab-monthly-busy-hour" title="Monthly Busy Hour">
          <MonthlyBusyHourChart />
        </Tab>
        <Tab eventKey="tab-yearly-busy-hour" title="Yearly Busy Hour">
          <YearlyBusyHourChart />
        </Tab>
        <Tab eventKey="tab-monthly-busy-day" title="Monthly Busy Date">
          <MonthlyBusyDayChart />
        </Tab>
        <Tab eventKey="tab-yearly-busy-day" title="Yearly Busy Date">
          <YearlyBusyDayChart />
        </Tab>
        <Tab eventKey="tab-monthly-busy-weekday" title="Monthly Busy Day">
          <MonthlyBusyWeekdayChart />
        </Tab>
        <Tab eventKey="tab-yearly-busy-weekday" title="Yearly Busy Day">
          <YearlyBusyWeekdayChart />
        </Tab>
      </Tabs>
    </>
  );
};

export default DashboardChart;
