import { createContext, useContext, useState } from "react";
import { getPartnerLogList, getPartnerLogChart } from "utils/api";
import { transformSnakeToCamelInArray, chartColors, transformChartToMultiDatasets } from "utils/helper";
import { useNotification } from "contexts/notification";

const paginationListDefault = {
  data: [],
  meta: {
    totalPage: 0,
    totalData: 0,
    totalDataPerPage: 0,
    currentPage: 0,
  },
};

const chartDefault = {
  labels: [],
  datasets: [],
};

const responseDefault = {
  type: null,
  status: false,
  message: null,
  data: null,
};

export const LogContext = createContext(null);

export const usePartnerLog = () => {
  const ctx = useContext(LogContext);

  if (!ctx) {
    throw new Error("usePartnerLog must be used within the LogProvider");
  }

  return ctx;
};

const LogProvider = ({ children }) => {
  const { pushNotification } = useNotification();

  const [partnerLogList, setPartnerLogList] = useState(paginationListDefault);
  const [partnerLogDailyChart, setPartnerLogDailyChart] = useState(chartDefault);
  const [partnerLogMonthlyChart, setPartnerLogMonthlyChart] = useState(chartDefault);
  const [partnerLogYearlyChart, setPartnerLogYearlyChart] = useState(chartDefault);
  const [partnerLogMonthlyBusyHourCharts, setPartnerLogMonthlyBusyHourCharts] = useState([]);
  const [partnerLogYearlyBusyHourCharts, setPartnerLogYearlyBusyHourCharts] = useState([]);
  const [partnerLogMonthlyBusyDayCharts, setPartnerLogMonthlyBusyDayCharts] = useState([]);
  const [partnerLogYearlyBusyDayCharts, setPartnerLogYearlyBusyDayCharts] = useState([]);
  const [partnerLogMonthlyBusyWeekdayCharts, setPartnerLogMonthlyBusyWeekdayCharts] = useState([]);
  const [partnerLogYearlyBusyWeekdayCharts, setPartnerLogYearlyBusyWeekdayCharts] = useState([]);
  const [response, setResponse] = useState(responseDefault);
  const [loading, setLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);

  const handleGetPartnerLogList = async (params) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "PARTNER_LOG_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getPartnerLogList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setPartnerLogList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
            totalDataPerPage: res.data.meta.total_data || params.limit,
            currentPage: params.page,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetPartnerLogChart = async (type) => {
    if (!type) return;

    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "PARTNER_LOG_CHART_" + type.toUpperCase(),
    }));
    setChartLoading(true);

    // api call
    try {
      const res = await getPartnerLogChart({ type });

      if (res.status === 200) {
        // set state
        if (type.endsWith("_busy_hour")) {
          let fn;
          if (type === "yearly_busy_hour") {
            fn = setPartnerLogYearlyBusyHourCharts;
          } else if (type === "monthly_busy_hour") {
            fn = setPartnerLogMonthlyBusyHourCharts;
          }

          if (fn) {
            const datasets = transformChartToMultiDatasets(res.data.data, "busy_hour", "count");
            fn(datasets);
          }
        } else if (type.endsWith("_busy_day")) {
          let fn;
          if (type === "yearly_busy_day") {
            fn = setPartnerLogYearlyBusyDayCharts;
          } else if (type === "monthly_busy_day") {
            fn = setPartnerLogMonthlyBusyDayCharts;
          }

          if (fn) {
            const datasets = transformChartToMultiDatasets(res.data.data, "busy_day", "count");
            fn(datasets);
          }
        } else if (type.endsWith("_busy_weekday")) {
          let fn;
          if (type === "yearly_busy_weekday") {
            fn = setPartnerLogYearlyBusyWeekdayCharts;
          } else if (type === "monthly_busy_weekday") {
            fn = setPartnerLogMonthlyBusyWeekdayCharts;
          }

          if (fn) {
            const datasets = transformChartToMultiDatasets(res.data.data, "busy_weekday", "count");
            fn(datasets);
          }
        } else {
          let fn;
          if (type === "yearly") {
            fn = setPartnerLogYearlyChart;
          } else if (type === "monthly") {
            fn = setPartnerLogMonthlyChart;
          } else if (type === "daily") {
            fn = setPartnerLogDailyChart;
          }

          if (fn) {
            const labels = res.data.data.map((x) => x.period_label);
            const counts = res.data.data.map((x) => x.count);

            fn((prev) => ({
              ...prev,
              labels: labels,
              datasets: [
                {
                  label: type.toUpperCase(),
                  data: counts,
                  backgroundColor: chartColors.blue,
                },
              ],
            }));
          }
        }

        setResponse((prev) => ({
          ...prev,
          data: res.data.data,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setChartLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  return (
    <LogContext.Provider
      value={{
        loading,
        chartLoading,
        response,
        partnerLogList,
        partnerLogDailyChart,
        partnerLogMonthlyChart,
        partnerLogYearlyChart,
        partnerLogMonthlyBusyHourCharts,
        partnerLogYearlyBusyHourCharts,
        partnerLogMonthlyBusyDayCharts,
        partnerLogYearlyBusyDayCharts,
        partnerLogMonthlyBusyWeekdayCharts,
        partnerLogYearlyBusyWeekdayCharts,
        getPartnerLogList: handleGetPartnerLogList,
        getPartnerLogChart: handleGetPartnerLogChart,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};

export default LogProvider;
