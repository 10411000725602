import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JSZip from "jszip";

import MergePefindoDetailTable from "components/Pefindo/MergePefindoDetailTable";

import { usePefindo } from "contexts/pefindo";
import { useNotification } from "contexts/notification";
import Skeleton from "react-loading-skeleton";

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
  );
}

const getZipItemFileName = (item, idx) => {
  if (!item) return `${uuidv4()}.pdf`;
  if (item.fileName?.length <= 100) return item.fileName;
  return `${`${idx + 1}`.padStart(3, "0")} ${item.createdAt.replace(/[-:\s]/g, "")}.pdf`;
};

const PefindoContainer = () => {
  const { id } = useParams();
  const { loading, mergePefindo, getPefindoList, pefindoList } = usePefindo();

  const { pushNotification } = useNotification();
  const [zipLoading, setZipLoading] = useState(false);

  const getDetailList = useCallback(async () => {
    await getPefindoList(id);
  }, []);

  useEffect(() => {
    getDetailList();
  }, []);

  const handleDownloadZip = async () => {
    if (!mergePefindo) return;
    if (!pefindoList?.data?.length) return;

    try {
      setZipLoading(true);

      const zip = new JSZip();

      const fetchAndAddToZip = async (zip, filename, fileUrl) => {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        zip.file(filename, blob);
      };

      const promises = pefindoList.data.map((item, idx) =>
        fetchAndAddToZip(zip, getZipItemFileName(item, idx), item.fileUrl),
      );
      await Promise.all(promises);

      const content = await zip.generateAsync({ type: "blob", compression: "DEFLATE" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = `finskor-${mergePefindo.createdAt.replace(/[-:\s]/g, "")}.zip`;
      link.click();

      pushNotification("success", "Download Success");
    } catch (e) {
      pushNotification("error", "Download Failed", e);
    } finally {
      setZipLoading(false);
    }
  };

  return (
    <>
      <h4 className="mb-4">Pefindo List ({pefindoList.data.length})</h4>

      {loading ? (
        <Skeleton height={250} className="rounded" />
      ) : (
        <>
          <div className="w-100 mb-3">
            <OverlayTrigger
              overlay={<Tooltip>Download All {pefindoList.data.length} Pefindo Files as Zip</Tooltip>}
              placement="right"
            >
              <Button onClick={handleDownloadZip} variant="outline-secondary" disabled={zipLoading}>
                {zipLoading && <FontAwesomeIcon icon={faCog} className="fa-spin fa-lg me-2" />}
                Download Zip
              </Button>
            </OverlayTrigger>
          </div>
          <MergePefindoDetailTable pefindoList={pefindoList.data} />
        </>
      )}
    </>
  );
};

export default PefindoContainer;
