import { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import NavigationBar from "components/NavigationBar";
import MergeStatementDashboardData from "components/MergeStatementDashboardData";
import MergeStatementDashboardMeta from "components/MergeStatementDashboardMeta";
import Footer from "components/Footer";
import AutocompleteInput from "components/Common/Autocomplete/AutocompleteInput";
import { useStatement } from "contexts/statement";
import { transformSnakeToCamelInArray } from "utils/helper";
import { getPartnerList as getPartnerListApi } from "utils/api";

const defaultParams = {
  page: 1,
  limit: 25,
  partner_id: "",
};

const Dashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("");

  // Gets default value from query params.
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  if (!Number.isNaN(Number.parseInt(page, 10)) && page > 0) {
    defaultParams.page = page;
  }

  const selectedPartnerUUID = queryParams.get("partner");

  const [params, setParams] = useState(defaultParams);
  const { getMergeStatementList, getPartnerList, partnerList } = useStatement();

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getList = useCallback(
    async (newParams = {}) => {
      const tempParams = params;

      const mergedParams = { ...params, ...newParams };
      if (newParams.partner_id !== params.partner_id) {
        setParams(mergedParams);

        await getMergeStatementList({
          ...tempParams,
          ...newParams,
        });
      }
    },
    [params],
  );

  const getSelectedPartnerByUUID = useCallback(
    async (uuid = null) => {
      const response = await getPartnerListApi({ uuid });

      if (response.data.data && response.data.data.length === 1) {
        const data = transformSnakeToCamelInArray(response.data.data);
        setSelectedFilter(data[0].displayFilter);
        getList({
          partner_id: data[0].id,
        });
        setIsCollapsed(false);
      }
    },
    [params],
  );

  const handleFilterPartnerList = async (search) => {
    await getPartnerList({ search });
  };

  const handleInputChange = (value) => {
    setSelectedFilter(value.displayFilter);
    const params = new URLSearchParams(location.search);
    params.set("partner", value.uuid);
    params.set("page", 1);
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    };
    navigate(options);
  };

  const resetFilter = () => {
    navigate("/statement-dashboard");
    getList({
      partner_id: "",
      page: 1,
    });
    defaultParams.page = 1;
    defaultParams.partner_id = "";
    setSelectedFilter("");
    setIsCollapsed(true);
  };

  useEffect(() => {
    if (!selectedPartnerUUID) {
      getList();
    }
  }, []);

  useEffect(() => {
    if (selectedPartnerUUID) {
      getSelectedPartnerByUUID(selectedPartnerUUID);
    }
  }, [location.search]);

  return (
    <>
      <NavigationBar />
      <Container style={{ marginBottom: "50px" }}>
        <div className="d-flex align-items-center mb-4">
          <h2 className="mb-0">Rekening Koran</h2>
          <button onClick={handleCollapseToggle} className="btn btn-outline-primary text-sm ms-3 ms-auto">
            Filter <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} className="ms-2" />
          </button>
        </div>
        <div className={`${isCollapsed ? "d-none" : "d-block"}`}>
          <div className="d-flex">
            <AutocompleteInput
              key={selectedFilter}
              defaultValue={selectedFilter}
              listData={partnerList.data}
              getData={handleFilterPartnerList}
              type="partner"
              propertyName="displayFilter"
              displayPropertyName="displayFilter"
              inputPlaceholder="Filter by partner name / partner email / partner company"
              onChangeValue={(value) => handleInputChange(value)}
              getAllPropertyInSelectedData
            />

            <button onClick={() => resetFilter()} className="btn btn-outline-primary w-25">
              Reset Filter
            </button>
          </div>
        </div>
        <div className="mb-4">
          <MergeStatementDashboardMeta />
        </div>
        <MergeStatementDashboardData params={params} getList={getList} />
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
