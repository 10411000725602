import { useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "contexts/auth";
import ModalBox from "components/ModalBox";

const NavigationBar = () => {
  const { Brand, Toggle, Collapse } = Navbar;
  const { Link: NavLink } = Nav;
  const { Item: DropdownItem } = NavDropdown;
  const [openModal, setOpenModal] = useState(false);
  const [labelRedirect, setLabelRedirect] = useState("");
  const [modalProperties, setModalProperties] = useState({
    title: "",
    body: "",
    type: "",
  });
  const { user, logout } = useAuth();
  const path = window.location.pathname || "";
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  const handleClickForceUpdate = (url) => {
    if (url === "/statement-dashboard") {
      // Navigate to a temporary route, then back to force rerender
      navigate("/temp-route", { replace: true });
      setTimeout(() => navigate(url), 0);
    } else {
      navigate(url);
    }
  };

  const setModalRedirect = (type) => {
    switch (type) {
      case "redirect-change-password":
        setOpenModal(true);
        setModalProperties({
          title: "Password Update Required",
          body: "Your password has expired and needs to be changed.",
          type,
        });
        setLabelRedirect("Redirect To Change Password Page");
        break;
      case "redirect-update-2fa":
        setOpenModal(true);
        setModalProperties({
          title: "Security Update Required",
          body: "Please review and update your Two-Factor Authentication (2FA) settings to ensure continued protection of your account. This will help safeguard against unauthorized access.",
          type,
        });
        setLabelRedirect("Redirect To Update 2FA Setting");
        break;
      default:
        break;
    }
  };

  const redirectToProfilePage = () => {
    if (modalProperties.type === "redirect-change-password") {
      navigate("/profile/change-password");
    } else if (modalProperties.type === "redirect-update-2fa") {
      navigate("/profile/two-factor-authentication");
    }
  };

  useEffect(() => {
    if (!path.includes("/profile")) {
      if (user && user.password_expired) {
        setModalRedirect("redirect-change-password");
      } else if (user && user.two_factor_authentication === "inactive") {
        setModalRedirect("redirect-update-2fa");
      }
    }
  }, [user]);

  return (
    <Navbar expand="lg" className="mb-4 shadow">
      <Container>
        <Brand href="/">
          <img alt="Logo" src="/img/logo.png" height="30" className="d-inline-block align-top" />
        </Brand>
        <Toggle />
        <Collapse className="justify-content-between">
          <Nav>
            <NavLink
              onClick={() => handleClickForceUpdate("/statement-dashboard")}
              className={`fw-bold ${
                ["statement"].some((x) => path.includes(x)) ? "border-primary border-bottom border-3" : ""
              }`}
              active={["statement"].some((x) => path.includes(x))}
            >
              Rekening Koran
            </NavLink>
            <NavLink
              onClick={() => handleClickForceUpdate("/slik-dashboard")}
              className={{
                "fw-bold border-primary border-bottom border-3": ["slik"].some((x) => path.includes(x)),
                "ms-3": true,
              }}
              active={["slik"].some((x) => path.includes(x))}
            >
              SLIK
            </NavLink>
            <NavLink
              as={Link}
              to="/pefindo-dashboard"
              className={{
                "fw-bold border-primary border-bottom border-3": ["pefindo"].some((x) => path.includes(x)),
                "ms-3": true,
              }}
              active={["pefindo"].some((x) => path.includes(x))}
            >
              Pefindo
            </NavLink>
            <NavLink
              as={Link}
              to="/partner-log-dashboard"
              className={{
                "fw-bold border-primary border-bottom border-3": ["partner-log"].some((x) => path.includes(x)),
                "ms-3": true,
              }}
              active={["partner-log"].some((x) => path.includes(x))}
            >
              Partner Log
            </NavLink>
            <NavLink
              as={Link}
              to="/finetuning-log-dashboard"
              className={{
                "fw-bold border-primary border-bottom border-3": ["finetuning-log"].some((x) => path.includes(x)),
                "ms-3": true,
              }}
              active={["finetuning-log"].some((x) => path.includes(x))}
            >
              Fine Tuning Log
            </NavLink>
          </Nav>
          <Nav>
            <NavDropdown title={user.name}>
              <DropdownItem onClick={() => navigate("/profile")}>Profile</DropdownItem>
              <DropdownItem onClick={handleLogout}>Keluar</DropdownItem>
            </NavDropdown>
          </Nav>
        </Collapse>
        <ModalBox
          show={openModal}
          title={modalProperties.title}
          body={modalProperties.body}
          isShowNegativeClick={false}
          showCloseButtonModal={false}
          labelPositiveClick={labelRedirect}
          onPositiveClick={() => redirectToProfilePage()}
        />
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
