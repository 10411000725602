import { Table } from "react-bootstrap";

const tableHeader = (
  <tr>
    <th style={{ width: "1%", minWidth: "30px" }}>#</th>
    <th>File Name</th>
    <th>Raw File</th>
    <th>Information</th>
    <th style={{ width: "120px" }}>Processed At</th>
    <th style={{ width: "110px" }}>Updated At</th>
    <th style={{ width: "110px" }}>Created At</th>
  </tr>
);

const PefindoTable = (props) => {
  const { pefindoList } = props;

  return (
    <>
      <Table responsive bordered striped hover size="sm" className="mb-3">
        <thead>{tableHeader}</thead>
        <tbody>
          {pefindoList.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <a href={item.fileUrl} target="_blank" rel="noreferrer">
                  {item.fileName}
                </a>
              </td>
              <td>
                <a href={item.rawUrl} target="_blank" rel="noreferrer">
                  Raw File
                </a>
              </td>
              <td>{item.pefindoSummary?.borrower_name}</td>
              <td>{item.processedAt}</td>
              <td>{item.createdAt}</td>
              <td>{item.updatedAt}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default PefindoTable;
