import { createContext, useContext, useState } from "react";
import {
  getMergeSlikList,
  getMergeSlik,
  getSlikList,
  getMergeSlikPartnerLogList,
  getSlikChart,
  getPartnerList,
} from "utils/api";
import {
  transformSnakeToCamelInArray,
  transformSnakeToCamelObject,
  transformChartToMultiDatasets,
  chartColors,
} from "utils/helper";
import { useNotification } from "contexts/notification";

const paginationListDefault = {
  data: [],
  meta: {
    totalPage: 0,
    totalData: 0,
    totalDataPerPage: 0,
    currentPage: 0,
  },
};

const responseDefault = {
  type: null,
  status: false,
  message: null,
  data: null,
};

const simpleListDefault = {
  data: [],
  meta: {
    totalData: 0,
  },
  default: true,
};

const chartDefault = {
  labels: [],
  datasets: [],
};

export const SlikContext = createContext(null);

export const useSlik = () => {
  const ctx = useContext(SlikContext);

  if (!ctx) {
    throw new Error("useSlik must be used within the SlikProvider");
  }

  return ctx;
};

const SlikProvider = ({ children }) => {
  const { pushNotification } = useNotification();
  const [mergeSlikList, setMergeSlikList] = useState(paginationListDefault);
  const [mergeSlik, setMergeSlik] = useState(null);
  const [mergeSlikPartnerLogList, setMergeSlikPartnerLogList] = useState(simpleListDefault);
  const [slikList, setSlikList] = useState(paginationListDefault);
  const [slikDailyChart, setSlikDailyChart] = useState(chartDefault);
  const [slikMonthlyChart, setSlikMonthlyChart] = useState(chartDefault);
  const [slikYearlyChart, setSlikYearlyChart] = useState(chartDefault);
  const [slikMonthlyPartnerCharts, setSlikMonthlyPartnerCharts] = useState([]);
  const [slikYearlyPartnerCharts, setSlikYearlyPartnerCharts] = useState([]);
  const [response, setResponse] = useState(responseDefault);
  const [chartLoading, setChartLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partnerList, setPartnerList] = useState(simpleListDefault);

  const handleGetMergeSlikList = async (params) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_SLIK_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergeSlikList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setMergeSlikList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
            totalDataPerPage: res.data.meta.total_data || params.limit,
            currentPage: params.page,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetPartnerList = async (params = { search: "", limit: "", uuid: "" }) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "PARTNER_LIST",
    }));

    // api call
    try {
      const res = await getPartnerList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setPartnerList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
          },
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetMergeSlik = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_SLIK",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergeSlik(id);

      if (res.status === 200) {
        const temp = transformSnakeToCamelObject(res.data.data);

        setMergeSlik((prev) => ({
          ...prev,
          ...temp,
        }));

        setResponse((prev) => ({
          ...prev,
          data: temp,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetMergeSlikPartnerLogList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_SLIK_PARTNER_LOG_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergeSlikPartnerLogList(id);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setMergeSlikPartnerLogList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev?.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
          },
          default: false,
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetSlikList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "SLIK_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getSlikList({ merge_uuid: id });

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setSlikList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetSlikChart = async (type) => {
    if (!type) return;

    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "SLIK_CHART_" + type.toUpperCase(),
    }));
    setChartLoading(true);

    // api call
    try {
      const res = await getSlikChart({ type });
      if (res.status === 200) {
        // set state
        if (type.endsWith("_partner")) {
          let fn;
          if (type === "yearly_partner") {
            fn = setSlikYearlyPartnerCharts;
          } else if (type === "monthly_partner") {
            fn = setSlikMonthlyPartnerCharts;
          }

          if (fn) {
            const datasets = transformChartToMultiDatasets(res.data.data, "partner_name", "processed_usage_count");
            fn(datasets);
          }
        } else {
          let fn;
          if (type === "yearly") {
            fn = setSlikYearlyChart;
          } else if (type === "monthly") {
            fn = setSlikMonthlyChart;
          } else if (type === "daily") {
            fn = setSlikDailyChart;
          }

          if (fn) {
            const labels = res.data.data.map((x) => x.period_label);
            const allCounts = res.data.data.map((x) => x.all_count);
            const processedUsageCounts = res.data.data.map((x) => x.processed_usage_count);

            fn((prev) => ({
              ...prev,
              labels: labels,
              datasets: [
                {
                  label: "UPLOADED",
                  data: allCounts,
                  backgroundColor: chartColors.blue,
                },
                {
                  label: "PROCESSED",
                  data: processedUsageCounts,
                  backgroundColor: chartColors.green,
                },
              ],
            }));
          }
        }

        setResponse((prev) => ({
          ...prev,
          data: res.data.data,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setChartLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  return (
    <SlikContext.Provider
      value={{
        loading,
        chartLoading,
        response,
        mergeSlikList,
        mergeSlik,
        mergeSlikPartnerLogList,
        slikDailyChart,
        slikMonthlyChart,
        slikYearlyChart,
        slikMonthlyPartnerCharts,
        slikYearlyPartnerCharts,
        slikList,
        partnerList: partnerList,
        getMergeSlikList: handleGetMergeSlikList,
        getMergeSlik: handleGetMergeSlik,
        getMergeSlikPartnerLogList: handleGetMergeSlikPartnerLogList,
        getSlikChart: handleGetSlikChart,
        getSlikList: handleGetSlikList,
        getPartnerList: handleGetPartnerList,
      }}
    >
      {children}
    </SlikContext.Provider>
  );
};

export default SlikProvider;
