export const getTodayDate = (usingTime = false, timeType = "placeholder") => {
  const today = new Date();

  const pad = (num) => num.toString().padStart(2, "0");

  // Get date parts
  const year = today.getFullYear();
  const month = pad(today.getMonth() + 1);
  const day = pad(today.getDate());
  const hours = pad(today.getHours());
  const minutes = pad(today.getMinutes());
  const seconds = pad(today.getSeconds());

  // Format date and time
  if (usingTime) {
    let dateWithTime = `${year}-${month}-${day}`;
    if (timeType === "placeholder") {
      dateWithTime += ` 00:00:00`;
    } else {
      dateWithTime += ` ${hours}:${minutes}:${seconds}`;
    }
    return dateWithTime;
  }

  // Format date only
  return `${year}-${month}-${day}`;
};
