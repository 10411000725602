import { createContext, useContext, useState } from "react";
import {
  getMergePefindoList,
  getMergePefindo,
  getPefindoList,
  getMergePefindoPartnerLogList,
  getPefindoChart,
} from "utils/api";
import {
  transformSnakeToCamelInArray,
  transformSnakeToCamelObject,
  transformChartToMultiDatasets,
  chartColors,
} from "utils/helper";
import { useNotification } from "contexts/notification";

const paginationListDefault = {
  data: [],
  meta: {
    totalPage: 0,
    totalData: 0,
    totalDataPerPage: 0,
    currentPage: 0,
  },
};

const responseDefault = {
  type: null,
  status: false,
  message: null,
  data: null,
};

const simpleListDefault = {
  data: [],
  meta: {
    totalData: 0,
  },
  default: true,
};

const chartDefault = {
  labels: [],
  datasets: [],
};

export const PefindoContext = createContext(null);

export const usePefindo = () => {
  const ctx = useContext(PefindoContext);

  if (!ctx) {
    throw new Error("usePefindo must be used within the PefindoProvider");
  }

  return ctx;
};

const PefindoProvider = ({ children }) => {
  const { pushNotification } = useNotification();
  const [mergePefindoList, setMergePefindoList] = useState(paginationListDefault);
  const [mergePefindo, setMergePefindo] = useState(null);
  const [mergePefindoPartnerLogList, setMergePefindoPartnerLogList] = useState(simpleListDefault);
  const [pefindoList, setPefindoList] = useState(paginationListDefault);
  const [pefindoDailyChart, setPefindoDailyChart] = useState(chartDefault);
  const [pefindoMonthlyChart, setPefindoMonthlyChart] = useState(chartDefault);
  const [pefindoYearlyChart, setPefindoYearlyChart] = useState(chartDefault);
  const [pefindoMonthlyPartnerCharts, setPefindoMonthlyPartnerCharts] = useState([]);
  const [pefindoYearlyPartnerCharts, setPefindoYearlyPartnerCharts] = useState([]);
  const [response, setResponse] = useState(responseDefault);
  const [chartLoading, setChartLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleGetMergePefindoList = async (params) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_SLIK_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergePefindoList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setMergePefindoList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
            totalDataPerPage: res.data.meta.total_data || params.limit,
            currentPage: params.page,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetMergePefindo = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_SLIK",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergePefindo(id);

      if (res.status === 200) {
        const temp = transformSnakeToCamelObject(res.data.data);

        setMergePefindo((prev) => ({
          ...prev,
          ...temp,
        }));

        setResponse((prev) => ({
          ...prev,
          data: temp,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetMergePefindoPartnerLogList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_SLIK_PARTNER_LOG_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergePefindoPartnerLogList(id);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setMergePefindoPartnerLogList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev?.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
          },
          default: false,
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetPefindoList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "SLIK_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getPefindoList({ merge_uuid: id });

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setPefindoList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetPefindoChart = async (type) => {
    if (!type) return;

    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "SLIK_CHART_" + type.toUpperCase(),
    }));
    setChartLoading(true);

    // api call
    try {
      const res = await getPefindoChart({ type });
      if (res.status === 200) {
        // set state
        if (type.endsWith("_partner")) {
          let fn;
          if (type === "yearly_partner") {
            fn = setPefindoYearlyPartnerCharts;
          } else if (type === "monthly_partner") {
            fn = setPefindoMonthlyPartnerCharts;
          }

          if (fn) {
            const datasets = transformChartToMultiDatasets(res.data.data, "partner_name", "processed_usage_count");
            fn(datasets);
          }
        } else {
          let fn;
          if (type === "yearly") {
            fn = setPefindoYearlyChart;
          } else if (type === "monthly") {
            fn = setPefindoMonthlyChart;
          } else if (type === "daily") {
            fn = setPefindoDailyChart;
          }

          if (fn) {
            const labels = res.data.data.map((x) => x.period_label);
            const allCounts = res.data.data.map((x) => x.all_count);
            const processedUsageCounts = res.data.data.map((x) => x.processed_usage_count);

            fn((prev) => ({
              ...prev,
              labels: labels,
              datasets: [
                {
                  label: "UPLOADED",
                  data: allCounts,
                  backgroundColor: chartColors.blue,
                },
                {
                  label: "PROCESSED",
                  data: processedUsageCounts,
                  backgroundColor: chartColors.green,
                },
              ],
            }));
          }
        }

        setResponse((prev) => ({
          ...prev,
          data: res.data.data,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setChartLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  return (
    <PefindoContext.Provider
      value={{
        loading,
        chartLoading,
        response,
        mergePefindoList,
        mergePefindo,
        mergePefindoPartnerLogList,
        pefindoDailyChart,
        pefindoMonthlyChart,
        pefindoYearlyChart,
        pefindoMonthlyPartnerCharts,
        pefindoYearlyPartnerCharts,
        pefindoList,
        getMergePefindoList: handleGetMergePefindoList,
        getMergePefindo: handleGetMergePefindo,
        getMergePefindoPartnerLogList: handleGetMergePefindoPartnerLogList,
        getPefindoChart: handleGetPefindoChart,
        getPefindoList: handleGetPefindoList,
      }}
    >
      {children}
    </PefindoContext.Provider>
  );
};

export default PefindoProvider;
