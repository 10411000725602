import { useEffect, useCallback, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { usePefindo } from "contexts/pefindo";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const options = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
};

const height = 300;

const DailyChart = () => {
  const { getPefindoChart, pefindoDailyChart, chartLoading } = usePefindo();
  const getChart = useCallback(async (type) => {
    await getPefindoChart(type);
  }, []);

  useEffect(() => {
    getChart("daily");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={pefindoDailyChart} options={options} />
        </div>
      )}
    </>
  );
};

const MonthlyChart = () => {
  const { getPefindoChart, pefindoMonthlyChart, chartLoading } = usePefindo();
  const getChart = useCallback(async (type) => {
    await getPefindoChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={pefindoMonthlyChart} options={options} />
        </div>
      )}
    </>
  );
};

const YearlyChart = () => {
  const { getPefindoChart, pefindoYearlyChart, chartLoading } = usePefindo();
  const getChart = useCallback(async (type) => {
    await getPefindoChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={pefindoYearlyChart} options={options} />
        </div>
      )}
    </>
  );
};

const MonthlyPartnerChart = () => {
  const { getPefindoChart, pefindoMonthlyPartnerCharts, chartLoading } = usePefindo();
  const getChart = useCallback(async (type) => {
    await getPefindoChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly_partner");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {pefindoMonthlyPartnerCharts.map((chart, index) => (
            <Col key={index}>
              <Pie data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const YearlyPartnerChart = () => {
  const { getPefindoChart, pefindoYearlyPartnerCharts, chartLoading } = usePefindo();
  const getChart = useCallback(async (type) => {
    await getPefindoChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly_partner");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {pefindoYearlyPartnerCharts.map((chart, index) => (
            <Col key={index}>
              <Pie data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const DashboardChart = () => {
  const [key, setKey] = useState("tab-na");
  return (
    <>
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="my-3" fill mountOnEnter>
        <Tab eventKey="tab-na" title="N/A"></Tab>
        <Tab eventKey="tab-daily" title="Daily">
          <DailyChart />
        </Tab>
        <Tab eventKey="tab-monthly" title="Monthly">
          <MonthlyChart />
        </Tab>
        <Tab eventKey="tab-yearly" title="Yearly">
          <YearlyChart />
        </Tab>
        <Tab eventKey="tab-monthly-partner" title="Monthly Partner">
          <MonthlyPartnerChart />
        </Tab>
        <Tab eventKey="tab-yearly-partner" title="Yearly Partner">
          <YearlyPartnerChart />
        </Tab>
      </Tabs>
    </>
  );
};

export default DashboardChart;
