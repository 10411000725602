import React, { useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import "./index.scss";

/**
 * ReplacePosition - A React component for replace position on all data to CR or DB.
 *
 * @param {Object} props - The props object.
 * @param {Array<Object>} props.data - The array of objects containing data. The component only processes string data types in these objects.
 * @param {Function} props.setData - Function to update the data array after replacements.
 * @param {React.Ref} ref - A React ref that can be used by the parent component to interact with the component's methods.
 * @param {boolean} [props.loading=false] - An boolean to disabling/enabling find replace component
 *
 * @example
 * // Example of using ReplacePosition
 * const data = [
 *   { timestamp: "2024-01-01 18:15:39", description: "IBIZ ERDEHA MULTI N TO SUCI JAYA ABADHI", amount: "62500000.00" },
 *   { timestamp: "2024-01-02 19:25:39", description: "TRANSFER TO JOHN DOE", amount: "5000000.00" }
 * ];
 *
 * const setData = (updatedData) => { ... };
 *
 * <ReplacePosition
 *   data={data}
 *   setData={setData}
 *   loading={loading}
 * />
 */

const ReplacePosition = ({ data = [], setData, loading }) => {
  const [positionValue, setPositionValue] = useState("");

  const { Select } = Form;

  const replaceAll = () => {
    if (data && data.length) {
      const modifiedData = data.map((val) => {
        return {
          ...val,
          position: positionValue,
        };
      });

      setData(modifiedData);
    }
  };

  return (
    <>
      <InputGroup className="mb-3">
        <InputGroup.Text>Replace All Position To</InputGroup.Text>
        <Select disabled={loading} value={positionValue} onChange={(e) => setPositionValue(e.target.value)}>
          <option value="" hidden>
            Choose Position
          </option>
          <option value="CR">CR</option>
          <option value="DB">DB</option>
        </Select>
        <Button onClick={() => replaceAll()} disabled={loading} variant="primary">
          Replace All
        </Button>
      </InputGroup>
    </>
  );
};

// Set the display name for better debugging
ReplacePosition.displayName = "ReplacePosition";

export default ReplacePosition;
