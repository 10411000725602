import MergeStatementDashboardTable from "components/MergeStatementDashboardTable";
import MergeStatementDashboardTableInfo from "components/MergeStatementDashboardTableInfo";

const Dashboard = ({ getList, params }) => {
  return (
    <>
      <MergeStatementDashboardTableInfo onPageChange={getList} limit={params.limit} />
      <MergeStatementDashboardTable />
      <MergeStatementDashboardTableInfo onPageChange={getList} limit={params.limit} />
    </>
  );
};

export default Dashboard;
