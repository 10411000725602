import MergeSlikDashboardTable from "components/MergeSlikDashboardTable";
import MergeSlikDashboardTableInfo from "components/MergeSlikDashboardTableInfo";

const Dashboard = ({ getList, params }) => {
  // Gets default value from query params.

  return (
    <>
      <MergeSlikDashboardTableInfo onPageChange={getList} limit={params.limit} />
      <MergeSlikDashboardTable />
      <MergeSlikDashboardTableInfo onPageChange={getList} limit={params.limit} />
    </>
  );
};

export default Dashboard;
